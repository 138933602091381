
import { IonPage, IonButton, IonContent, IonFooter, IonIcon, IonLoading, onIonViewWillEnter } from '@ionic/vue';
import { LoginService } from '@/services';
import { useRouter, useRoute } from 'vue-router';
import bellIcon from '../../resources/icons/bell-square.svg';
import bigPicture from '../../resources/icons/bigPicture.svg';
import { ref } from 'vue';
import BaseCard from '@/components/Card/BaseCard.vue';
import userFull from '../../resources/icons/user-square.svg';
import { useToast } from '@/composables/useToast';

export default {
  name: 'InvalidLink',
  components: {
    IonPage,
    IonButton,
    IonContent,
    IonFooter,
    IonIcon,
    IonLoading,
    BaseCard
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loginService = new LoginService();
    const email = ref<string>('');
    const isLoading = ref<boolean>(false);
    const toast = useToast();

    onIonViewWillEnter(async () => {
      await setEmailFromRouteParams();
    });

    const setEmailFromRouteParams = async () => {
      if (!route.params.email) {
        await router.push({ name: 'login' });
      }
      email.value = route.params.email as string;
    };

    const sendMagicLink = async () => {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;

      try {
        await loginService.validateEmail({
          email: email.value
        });
        await router.push({
          name: 'check-your-email',
          params: {
            email: email.value
          }
        });
      } catch (e) {
        await toast.open({
          message: 'The email failed to send. Please check your email and try again.',
          buttons: ['Close'],
          color: 'danger'
        });
        await router.push({
          name: 'login'
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      bigPicture,
      sendMagicLink,
      email,
      bellIcon,
      userFull,
      isLoading,
      setEmailFromRouteParams
    };
  }
};
